import '../../App.css';
import ca from './images/catharsis.PNG';
export default function catharsis() {
    return (
        <thing>
            <h1>CATHARSIS</h1>
            <hr />
            <p>Dec 2024 | Play + Installation | <a target="_blank" rel="noreferrer" href="https://www.catharsisplay.online/">Play here</a> </p>
            <p></p>
            <br />
            <img src={ca} width='100%' alt="a black screen with white text: When I speak, I savor every word like it's delicious. Every cell in my body knows that I'm going to win this fight. It's like I'm being propelled forward, higher power in the shape of my tongue and teeth. I never stutter. I never cry. I am always right. to the left, green text reads: Hold LEFT SHIFT, ENTER, G." />
            <br />
            <br />
            <p>CATHARSIS is a computer-mediated play for one. As the performer/player, you must engage with your own computer in order to read the scenes, complicating technology as an agent 
                operating between script, stage, set, and co-performer. Each of the four vignette scenes explores a difficult-to-express emotion through a different set of interactions. 
            </p>
            <ul>
                <li>SCENE 1: BANGBANGBANG. Someone is at the door and you’ve had enough.</li>
                <li>SCENE 2: NAME. A confrontation with a loved one about your name.</li>
                <li>SCENE 3: CHOICES. Do you feel like your decisions matter?</li>
                <li>SCENE 4: DREAM. The last word, finally.</li>
            </ul>
            <p>Made and installed for Theater Arts 294, Future Stages.</p>
            <hr />
            <p>Are you angry?</p>
        </thing>
        
    )

}