import '../../App.css';
import d from './images/dates.png';
export default function dates() {
    return (
        <thing>
            <h1>3 Dates</h1>
            <hr />
            <p>Dec 2024 | Visual Novel | <a target="_blank" rel="noreferrer" href="https://www.bit.ly/3datesgame">Play here</a> </p>
            <p></p>
            <br />
            <img src={d} width='100%' alt="a pixelated movie theater with a talksprite of 'you' saying, 'Phew, made it just in time. Let's see, it's 5:17 now. I wonder if they're here yet?" />
            <br />
            <br />
            <p>A tiny visual novel about going on 3 dates, based on ideas by my partner. (It's made in Figma and a bit rough around the edges, so it might take a while to load.)
                Made for Computational Media 179, Game Design Practicum: Experimental Gameplay. 
            </p>
            <hr />
            <p>Starring my partner and I! Sort of. Don't take it too seriously.</p>
        </thing>
        
    )

}